<template>
  <a-row
    :md="{ span: 24 }"
    :sm="{ span: 24 }"
    :xs="{ span: 24 }"
  >
  <a-flex justify='space-between' align='center' class='w100'>
    <a-flex vertical justify='space-between' align='start'>
      <h6 class='point-label'>{{ $t('track_page.from') }}</h6>
      <p class='city'>{{ data.from }}</p>
    </a-flex>
    <a-flex vertical justify='space-between' align='end'>
      <h6 class='point-label'>{{ $t('track_page.to') }}</h6>
      <p class='city'>{{ data.to }}</p>
    </a-flex>
  </a-flex>
  </a-row>
</template>

<script>
export default {
  name: 'CityPoints',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style scoped>
.point-label{
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #07011F;
  text-transform: capitalize;
}

.city{
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: right;
  color: #07011F;
  text-transform: capitalize;
}
</style>