import axios from 'axios';

export class HttpWrapper {
    constructor(baseURL) {
        this.api = axios.create({
            baseURL,
            timeout: 60000,
        });
    }

    async get(url, params) {
        try {
            const response = await this.api.get(url, { params });
            return response.data;
        } catch (error) {
            console.error('Ошибка при отправке GET-запроса:', error);
            throw error;
        }
    };

    async post(url, data) {
        try {
            const response = await this.api.post(url, data);
            return response.data;
        } catch (error) {
            console.error('Ошибка при отправке POST-запроса:', error);
            throw error;
        }
    };
}
