import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import NotFoundView from '@/views/NotFound.vue';
import BusinessView from '@/views/BusinessView.vue';
import TrackParcel from '@/views/TrackParcel.vue';

const routes = [
  {
    path: '/:lang?',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/:lang?/business',
    name: 'business',
    component: BusinessView,
  },
  {
    path: '/:lang?/track/:id?',
    name: 'tracking',
    component: TrackParcel,
  },
  {
    path: '/',
    redirect: '/en',
  },
  {
    path: '/:lang?/:pathMatch(.*)*', // Должен быть всегда последним!!!!!
    name: 'notFound',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
