<template>
  <layout-page 
    :anchors="[
      { path: 'service', label: $t('links.service') },
      { path: 'delivery', label: $t('links.delivery') },
      { path:'business', label: $t('links.business') },]" 
    :activeSection='activeSection'>
    <TrackingSection />
    <DeliverySection />
    <BusinessSection />
    <AboutSection />
    <DeliveryStagesSection />
  </layout-page>
</template>

<script>
import TrackingSection from '@/components/home/TrackingSection'
import DeliverySection from '@/components/home/DeliverySection'
import LayoutPage from "@/components/LayoutPage.vue";
import BusinessSection from '@/components/home/BusinessSection';
import AboutSection from '@/components/home/AboutSection';
import DeliveryStagesSection from '@/components/home/DeliveryStagesSection';

export default {
  name: 'HomeView',
  components: {
    LayoutPage,
    TrackingSection,
    DeliverySection,
    BusinessSection,
    AboutSection,
    DeliveryStagesSection
  },
  data: () => ({
    activeSection: 'service',
  }),
  methods: {
    visibleSection() {
      const sections = document.querySelectorAll('section');
      for (const section of sections) {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight) {
          this.activeSection = section.id;
        }
      }
      return null;
    }
  },
  created () {
    window.addEventListener('scroll', this.visibleSection);
  },
  unmounted () {
    window.removeEventListener('scroll', this.visibleSection);
  },
}
</script>
