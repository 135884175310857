<template>
  <a-layout-footer :style="{backgroundColor: '#1B1B1B'}">
    <a-row class='footer-row top'>
      <a-row>
        <a-col
        :md="{ span: 18 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        >
        <h5 class='footer-title'>{{ $t('footer.title') }}</h5>
        <a-button
          type="primary"
          class="sent-btn"
          @click="redirectTo('https://t.me/parcelsupport_bot')"
        >
          {{ $t('links.connect_with_us') }}
          <img src="@/assets/images/Button-sent.svg" alt="sent" width="40px" class='btn-icon'/>
        </a-button>
        </a-col>
        <a-col
        :md="{ span: 6 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        >
        <ul class='social-list'>
          <li class='social-list-item'
            @click="redirectTo('https://t.me/ParcelPoint_bot')"
          >
          {{ $t('links.telegram_bot') }}
          </li>
          <li class='social-list-item my-5'>Facebook</li>
          <li class='social-list-item'>WhatsApp</li>
        </ul>
        </a-col>
      </a-row>
    </a-row>
    <a-row class='footer-row bread-crams-row'>
      <a-flex 
        align='start' justify='start'
        class="flex-grow logo"
      >
        <img src="@/assets/images/parcel point footer.svg" width="162px" />
      </a-flex>
      <a-flex
      >
        <router-link
          :to="`/${$route.params.lang}/track/`"
          class="bread-crams-link hide-on-small-screen"
        >
         {{ $t('links.track_parcel') }}
        </router-link>
      </a-flex>
      <a-flex
        class="mb-10"
      >
        <a
          @click="redirectTo('https://t.me/ParcelPoint_bot')"
          class="bread-crams-link px-12 hide-on-small-screen"
        >
          {{ $t('links.send_parcel') }}
        </a>
      </a-flex>
      <a-flex
        :md="{ span: 3 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
      >
        <router-link
          :to="`/${$route.params.lang}/business/`"
          class="bread-crams-link hide-on-small-screen"
        >
          {{ $t('links.for_business') }}
        </router-link>
      </a-flex>
      <a-divider class='divider' />
    </a-row>
    <a-row align='center' class='footer-row privacy-info-row'>
      <a-flex align='start' class='privacy-info-block'>
        <a-flex
          align='start'
          class='flex-grow pr-7 privacy-info-item'
        >
          <span class="bread-crams-link">@ 2024 Parcelpoint LLC</span>
        </a-flex>
        <a-flex
          align='center'
          class="px-7 privacy-info-item"
        >
          <router-link
            :to="`/${$route.params.lang}/public-offer/`"
            class="bread-crams-link"
            @click="redirectTo('https://t.me/parcelsupport_bot')"
          >
            {{ $t('links.public_offer') }}
          </router-link>
        </a-flex>
        <a-flex
          align='center'
          class="px-7 privacy-info-item"
        >
          <router-link
            :to="`/${$route.params.lang}/privacy-policy/`"
            class="bread-crams-link"
            @click="redirectTo('https://t.me/parcelsupport_bot')"
          >
            {{ $t('links.privacy_policy') }}
          </router-link>
        </a-flex>
        <a-flex
          align='end'
          class="pl-7 privacy-info-item"
        >
          <a 
            class='bread-crams-link' 
            href="mailto:send@parcelpoint.us"
          >
            send@parcelpoint.us
          </a>
        </a-flex>
      </a-flex>
  </a-row>
  </a-layout-footer>
</template>

<script>
export default {
  name: 'FooterMain',
  methods: {
    redirectTo(link) {
      window.open(link, '_blank') ;
    }
  }
}
</script>

<style scoped>
.footer-row{
  max-width: 1280px;
  padding: 0px 100px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}

.top{
  margin-top: 0px;
}

.footer-title{
  font-family: Raleway;
  font-size: 96px;
  font-weight: 500;
  line-height: 112.7px;
  text-align: left;
}

.sent-btn{
  position: relative;
  max-width: 224px;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  padding-left: 16px;
  border: 1px solid #fff;
  background-color: #1B1B1B;
  color:#fff;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.sent-btn:hover{
  background-color: #fff;
  color: #1B1B1B;
  border: 1px solid #1B1B1B;
}

.btn-icon{
  position: absolute;
  right: 7px;
  top: 7px;
}

.social-list{
  list-style: none;
  margin-top: 67px;
}

.social-list-item{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;
  cursor: pointer;
}

.divider{
  height: 1px; 
  background-color: #D1D1D1;
  position: absolute; 
  left: 50%;
  bottom: 0px; 
  transform: translateX(-50%); 
  width: 100vw; 
  margin: 0px;
}

.bread-crams-row{
  margin-top: 120px;
  position: relative;
}

.bread-crams-link{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #fff;
  white-space: nowrap;
}

.privacy-info-row{
  padding-bottom: 0px;
}

.privacy-info-block{
  max-width: 750px; 
  width: 100%;
}

.privacy-info-item{
  border-right:1px solid #fff; 
  margin-top:46px
}

.privacy-info-item:last-child{
  border-right:none; 
}

@media screen and (max-width: 968px) {
  .footer-title {
    font-size: 70px;
    line-height: 80px;
  }

  .privacy-info-block{
    flex-direction: column;
  }

  .privacy-info-item {
    border-right: none;
    padding: 0;
    margin-top: 20px;
  }

  .privacy-info-item:first-child{
    margin-top: 48px;
  }
}

@media screen and (max-width: 820px) {
  .footer-title {
    font-size: 64px;
    line-height: 70px;
  }

  .hide-on-small-screen {
    display: none;
  }

  .logo{
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 765px) {
  .footer-title {
    font-size: 32px;
    line-height: 37.57px;
  }

  .sent-btn{
    margin-top: 32px;
  }

  .social-list{
    margin-top: 48px;
    padding-left: 0;
    margin-bottom: 0;
  }

  .social-list-item{
    text-align: left;
  }

  .top{
    margin-top: 80px;
  }

  .footer-row{
    padding: 0 16px;
  }

  .bread-crams-row{
    margin-top: 20px;
    padding: 0 16px 56px 16px;
  }

  .privacy-info-row{
    padding: 0 16px 56px 16px;
  }
}

</style>