<template>
  <section
    id="service"
    style="scroll-margin-top: 200px !important;"
  >
    <a-row class="section-row mt-15 mt-sm-0">
      <a-col
        :md="{ span: 14 }"
        :sm="{ span: 24 }"
        class="w100 flex align-center justify-center mt-lg-15 mt-md-0 mt-sm-0 mb-sm-5"
      >
        <div class="left-bar-content mt-5 mt-sm-0">
          <h2 class="title" v-html="$t('trackingSection.title')"></h2>
          <div class="search-component">
            <h4>{{$t('trackingSection.tracking_number')}}</h4>
            <a-input
              v-model:value="trackNumber" 
              :placeholder="$t('trackingSection.enter_tracking_number')" 
              class="search-input"
              size="large"
              @input='onInput'
              @pressEnter='redirectToTracking'
            >
              <template #suffix>
                <a-button
                  type="primary"
                  size="large"
                  color='primary'
                  :disabled="!isValid"
                  :icon="h(SearchOutlined)"
                  @click="redirectToTracking"
                />
              </template>
            </a-input>
            <div class='help-message'>
              <span>{{helpMessage}}</span>
            </div>
            <router-link
              :to="`/${$route.params.lang}/calculator/`"
              class="inline-flex calculator-link mt-2"
            >
              <img
                class="calculator-icon"
                src="@/assets/images/Icon-calculator.svg"
                width="24px"
              >
              <span class="mx-2">{{ $t('links.calculate_delivery') }}</span>
            </router-link>
          </div>
        </div>
      </a-col>
      <a-col
        :md="{ span: 10 }"
        :sm="{ span: 24, order: 2 }"
        class="right-bar w100 flex align-center justify-end"
      >
        <img
          src="@/assets/images/Header Image 1.avif"
          alt="layer"
          class="bar-img"
        >
      </a-col>
    </a-row>
    <a-row class="creepting-row">
      <div class="creepting-lines">
        <CreepingLine
          text="KAZAKHSTAN USA RUSSIA UZBEKISTAN"
          :rotation-deg="-4.68"
          background-color="#07011F"
          spin="left"
        />
        <CreepingLine
          text="PARCELPOINT"
          :rotation-deg="5.62"
          background-color="#FC0004"
          spin="right"
        />
      </div>
    </a-row>
    <a-row class="section-row mt-lg-10">
      <a-col
        :md="{ span: 14, order: 1 }"
        :sm="{ span: 24, order: 1 }"
        :xs="{ span: 24, order: 1 }"
        class="flex align-center justify-center pr-2 mt-sm-10"
      >
        <img
          src="@/assets/images/Image Services.avif"
          alt="services"
          class="bar-img"
        >
      </a-col>
      <a-col
        :md="{ span: 10, order: 2 }"
        :sm="{ span: 24, oreder: 2 }"
        :xs="{ span: 24, oreder: 2 }"
        class="right-bar flex flex-column align-center justify-center mt-md-15 mt-sm-0"
      >
        <div class="flex flex-column align-center justify-center">
          <h5 class="deliver-doc-title">
            {{ $t('trackingSection.deliver_documents') }}
          </h5>
          <div class="deliver-doc-btn-row flex align-center justify-start">
            <a-button
            type="primary"
            class="deliver-doc-btn btn mr-2"
            @click="redirectTo('https://t.me/parcelsupport_bot')"
            >
              {{ $t('links.for_individuals') }}
            </a-button>
            <a-button
              type="primary"
              class="deliver-doc-btn btn ml-2 btn-black"
              @click="$router.push('/business')"
            >
              {{ $t('links.for_business') }}
            </a-button>
          </div>
          <a-row class="deliver-doc-advantages mt-lg-5 mt-md-0">
            <a-col
              :md="{ span: 12 }"
              :xs="{ span: 24 }"
            >
              <div class="deliver-doc-advantages-item flex align-center justify-start my-5">
                <img
                  src="@/assets/images/lightning.svg"
                  alt="delivery"
                  class="deliver-doc-advantages-icon mr-1"
                >
                <span class="deliver-doc-advantages-text">{{ $t('trackingSection.terms_from_10_days') }}</span>
              </div>
              <div class="deliver-doc-advantages-item flex align-center justify-start">
                <img
                  src="@/assets/images/Box_alt.svg"
                  alt="delivery"
                  class="deliver-doc-advantages-icon mr-1"
                >
                <span class="deliver-doc-advantages-text">{{ $t('trackingSection.delivery_to_door') }}</span>
              </div>
            </a-col>
            <a-col
              :md="{ span: 12 }"
              :xs="{ span: 24 }"
            >
              <div class="deliver-doc-advantages-item flex align-center justify-start my-5">
                <img
                  src="@/assets/images/Desk_alt.svg"
                  alt="delivery"
                  class="deliver-doc-advantages-icon mr-1"
                >
                <span class="deliver-doc-advantages-text">{{ $t('trackingSection.wide_range_services') }}</span>
              </div>
              <div class="deliver-doc-advantages-item flex align-center justify-start">
                <img
                  src="@/assets/images/Star.svg"
                  alt="delivery"
                  class="deliver-doc-advantages-icon mr-1"
                >
                <span class="deliver-doc-advantages-text">{{ $t('trackingSection.unique_approach') }}</span>
              </div>
            </a-col>
          </a-row>
          <div class="deliver-doc-btn-row flex align-center justify-start mt-10">
            <a-button
              type="primary"
              class="btn-large deliver-doc-advantages-btn mr-5"
              @click="redirectTo('https://t.me/ParcelPoint_bot')"
            >
              {{ $t('links.arrange_delivery') }}
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </section>
</template>

<script setup>
import { h } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
</script>

<script>
import CreepingLine from '@/components/CreepingLine.vue'
export default {
    name: 'TrackingSection',
    components: {
      CreepingLine
    },
    data (){
    return {
      isLoading: false,
      trackNumber: '',
      helpMessage: '',
      isValid: false,
    }
  },
  methods: {
    onInput() {
      this.isValid = false;
      if (this.trackNumber.length < 2) { 
        this.helpMessage = '';
        return
      }
      if (!(/^p{2}/i.test(this.trackNumber))) {
        this.helpMessage = this.$t('messages.track_number_must_start_with_pp');
      } else if (this.trackNumber.length < 15) {
        this.helpMessage = this.$t('messages.track_number_must_be_15_characters_long');
      } else if (this.trackNumber.length > 15) {
        this.helpMessage = this.$t('messages.track_number_too_long');
      } else{
        this.isValid = true;
        this.helpMessage = this.$t('messages.press_enter_or_search');
      }

    },
    redirectTo(link) {
      window.open(link, '_blank') ;
    },
    redirectToTracking() {
      if (this.isValid) {
      this.$router.push(`/${this.$route.params.lang}/track/${this.trackNumber}/`)
      }
    }
  },
}
</script>

<style scoped>

.left-bar-content{
  max-width: 530px;
}

.title{
  font-family: Raleway;
  font-size: 48px;
  font-weight: 500;
  line-height: 56.35px;
  text-align: left;
}

.search-component h4{
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #07011F;
}

.search-input {
  max-width: 510px;
  position: relative;
  margin: 4px 0 4px 0;
  border-radius: 12px;
}

.search-btn{
  position: absolute;
  right: 8px;
}

.help-message{
  height: 17px;
  padding-left: 12px;
  width: 100%;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color:#FE999B;
}

.calculator-link{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #FC0004;
}

.creepting-row{
  height: 350px;
  overflow-x: hidden;
}

.creepting-lines{
  position: absolute;
  top: 633px;
  left: 0px;
  right: 0;
  width: 100%;
  z-index: 7;
  overflow: hidden;
  height: 450px;
}

.deliver-doc-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #000000;
}

.deliver-doc-btn-row{
  width: 100%;
}

.deliver-doc-btn{
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  letter-spacing: 0.01em;
  text-align: left;
}

.deliver-doc-advantages{
  width: 100%;
}

.deliver-doc-advantages-text{
  font-family: Raleway;
  font-size:  16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.deliver-doc-advantages-btn{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

@media screen and (max-width: 1150px) {
  .title {
    font-size: 40px;
    line-height: 50px;
  }

  .creepting-lines{
    top: 500px;
    height: 250px;
  }

  .creepting-row{
    height: 250px;
  }

  .deliver-doc-title{
    font-size: 32px;
    line-height: 38px;
  }

  .deliver-doc-advantages-text{
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 36px;
        line-height: 38px;
    }

    .creepting-lines{
        top: 450px
    }

    .creepting-row{
        height: 200px;
    }
    .deliver-doc-title{
        font-size: 28px;
        line-height: 32px;
    }
    .deliver-doc-btn-row{
        flex-direction: column;
    }
    .deliver-doc-btn{
        margin: 0;
        margin-bottom: 10px;
    }

    .deliver-doc-advantages{
        margin-top: 0;
    }

    .deliver-doc-advantages-text{
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (max-width: 960px) {
    .title {
        font-size: 30px;
        line-height: 36px;
    }

    .creeptng-lines{
        top: 671px;
    } 

    .deliver-doc-title{
        font-size: 28px;
        line-height: 32px;
    }
    
}

@media screen and (max-width: 820px) {
    .creepting-lines{
        top: 450px
    }

    .creepting-row{
        height: 200px;
    }
}

@media screen and (max-width: 766px) {
    .right-bar{
        justify-content: center;
    }

    .creepting-lines{
        top: 633px
    }

    .creepting-row{
        height: 150px;
    }

    .deliver-doc-title{
        font-size: 24px;
        font-weight: 500;
        line-height: 28.18px;
        text-align: left;
    }

    .deliver-doc-btn-row{
        flex-direction: row;
    }

    .deliver-doc-btn{
        margin: 0;
        margin-right: 15px;
    }
}

</style>