<template>
  <a-steps
    :responsive="false"
    direction="horizontal"
    :current="stepIndex"
    class="custom-step"
    :items="[
      {
        title:  $t('track_page.dispatch'),
        description: stepIndex === 0 && city,
      },
      {
        title: $t('track_page.on_way'),
        description: stepIndex === 1 && city,
      },
      {
        title: $t('track_page.delivery'),
        description: stepIndex === 2 && city,
      },
      {
        title: $t('track_page.received'),
        description: stepIndex === 3 && city,
      },
    ]"
  >
    <template #progressDot="{ prefixCls, status }">
      <span
        :class="{
          [`${prefixCls}-icon-dot`]: true,
          [`${prefixCls}-icon-dot_active`]: status === 'process'
        }"
      >
        <img
          v-if="status === 'process'"
          src="@/assets/images/airship.svg"
          alt=""
        >
      </span>
    </template>
  </a-steps>
</template>

<script setup>
import {RouteStepEnum} from "@/constants/RouteStep";
import {ref, watch} from "vue";

const props = defineProps({
  step: {
    type: String,
    default: () => null
  },
  city: {
    type: String,
    default: () => null
  }
});

const stepIndex = ref(0);
resolveStepIndex();

watch(
    () => props.step,
    () => {
      resolveStepIndex();
    }
);

function resolveStepIndex() {
  switch(props.step) {
    case RouteStepEnum.CREATED:
      stepIndex.value = 0;
      break
    case RouteStepEnum.IN_TRANSIT:
      stepIndex.value = 1;
      break
    case RouteStepEnum.DELIVERY:
      stepIndex.value = 2;
      break
    case RouteStepEnum.DELIVERED:
      stepIndex.value = 3;
      break
  }
}
</script>

<style scoped lang="scss">
.custom-step::v-deep {
  $contentWidth: 60px;
  $leftPadding: 66px;
  $iconSize: 20px;
  $activeIconSize: 40px;

  position: relative;
  right: 62px;
  width: calc(100% + $leftPadding);

  * {
    cursor: default !important;
  }

  .ant-steps-item {

    &:not(:last-child) .ant-steps-item-content {
      position: relative;
      left: calc((140px - $contentWidth) / 2);
      width: $contentWidth;
    }

    &:first-child .ant-steps-item-content {
      width: 100%;
      min-width: 100px;
      position: relative;
      left: $leftPadding;
      text-align: left;
    }

    &:last-child .ant-steps-item-content {
      width: 100%;
      position: relative;
      right: 8px;
      text-align: right;
    }

    .ant-steps-item-title {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #07011F !important;
    }

    .ant-steps-item-description {
      font-family: 'Raleway', sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #717171 !important;
    }

    .ant-steps-icon-dot {
      position: absolute;
      width: $iconSize;
      height: $iconSize;
      top: calc(50% + 4px);
      left: 50%;
      transform: translate(-50%, -50%);

      &_active {
        width: $activeIconSize;
        height: $activeIconSize;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // alignment of general item to start and and block
    &:first-child .ant-steps-icon-dot {
      left: calc(50% + 6px);
    }

    &:last-child .ant-steps-icon-dot {
      left: calc(50% - 6px);
    }

    // alignment of tail line
    .ant-steps-item-tail::after {
      width: calc(100% - 20px);
      margin-inline-start: 10px;
    }

    // alignment of last tail line relatively to offset of dot
     &:nth-last-child(2) .ant-steps-item-tail::after {
      width: calc(100% - 26px);
      margin-inline-start: 10px;
    }


    // alignment of active item to start and end of block
    &:first-child.ant-steps-item-active .ant-steps-icon-dot {
      left: calc(50% + 16px);
    }

    &:last-child.ant-steps-item-active .ant-steps-icon-dot {
      left: calc(50% - 16px);
    }
  }
}
</style>
