<template>
  <section>
    <a-row class='flex align-center justify-center mb-12'>
      <h3 class='stages-title'>{{ $t('deliveryStages.title') }}</h3>
    </a-row>
    <a-row class='stages-card-row'>
      <img 
        src="@/assets/images/Vector 110.svg" 
        alt="Oval" 
        class="background md"
      >
      <img 
        src="@/assets/images/Vector 110 sm.svg" 
        alt="Oval" 
        class="background sm"
      >
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex flex-column justify-start align-center'
      >
      <img src="@/assets/images/phone01.png" class='stages-card-img' alt='01' />
      <p class='stages-card-text mt-6'>{{ $t('deliveryStages.contactManager') }}</p>
      </a-col>
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex flex-column justify-start align-center'
      >
        <img src="@/assets/images/box02.png" class='stages-card-img' alt='02' />
        <p class='stages-card-text mt-6' v-html="$t('deliveryStages.parcelCollection')"></p>
      </a-col>
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex flex-column justify-start align-center'
      >
        <img src="@/assets/images/tractor03.png" class='stages-card-img' alt="03" />
        <p class='stages-card-text mt-6'>{{ $t('deliveryStages.sendToWarehouse') }}</p>
      </a-col>
    </a-row>
    <a-row class='mt-13'>
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex flex-column justify-start align-center'
      >
        <img src="@/assets/images/note04.png" class='stages-card-img' alt="04" />
        <p class='stages-card-text mt-6'>{{ $t('deliveryStages.customsClearance') }}</p>
      </a-col>
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex flex-column justify-start align-center'
      >
        <img src="@/assets/images/plane05.png" class='stages-card-img' alt='05' />
        <p class='stages-card-text mt-6'>{{ $t('deliveryStages.deliveryToDestination') }}</p>
      </a-col>
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex flex-column justify-start align-center'
      >
        <img src="@/assets/images/parcel06.png" class='stages-card-img' alt="06" />
        <p class='stages-card-text mt-6'>{{ $t('deliveryStages.parcelReceiving') }}</p>
      </a-col>
    </a-row>
    <a-row class="outer-row">
      <a-flex class='post-row'>
        <a-flex justify='center' align='center' class='post-card'>
          <img src='@/assets/images/CDEK.svg' class='post-card-img' alt="cdek" />
        </a-flex>
        <a-flex justify='center' align='center' class='post-card'>
          <img src='@/assets/images/Fedex.svg' class='post-card-img' alt="fedex" />
        </a-flex>
        <a-flex justify='center' align='center' class='post-card'>
          <img src='@/assets/images/DHL.svg' class='post-card-img' alt="dhl" />
        </a-flex>
        <a-flex justify='center' align='center' class='post-card'>
          <img src='@/assets/images/US Postal.svg' class='post-card-img' alt="US postal" />
        </a-flex>
        <a-flex justify='center' align='center' class='post-card'>
          <img src='@/assets/images/UPS.svg' class='post-card-img' alt="UPS" />
        </a-flex>
      </a-flex>
    </a-row>
  </section>
</template>

<script>
export default {
  name: 'DeliveryStagesSection'
}
</script>

<style scoped>
.stages-card-row{
  position: relative;
}
.background{
  position: absolute;
  top: 60px;
  right: -60px;
  width: 88%; 
  height: auto;
}

.sm{
  display: none;
  max-width: 335px;
  width: 100%;
  height: 1981px;
  left: 50%; 
  transform: translateX(-50%);
}

.stages-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: center;
  color: #07011F;
}

.stages-card-text{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #262626;
}

.stages-card-img{
  max-width: 239px;
  width: 100%; 
  max-height: 239px;
  height: auto;
}

.outer-row{
  --row-width: calc(100vw - 200px);
  --max-height: 254px;
  max-height: var(--max-height); 
  height: calc(min(var(--row-width), var(--max-height) * 5) / 5);
  margin: 160px 0px;
}

.post-row{
  --row-width: calc(100vw - 220px);
  --max-height: 254px;
  width: var(--row-width); 
  max-height: var(--max-height); 
  height: calc(min(var(--row-width), var(--max-height) * 5) / 5);
  border-top:1px solid #D1D1D1;
  border-bottom:1px solid #D1D1D1; 
  position: absolute; 
  left: 50%; 
  transform: translateX(-50%); 
  margin: 0px;
}

.post-card{
  padding: 20px;
  width: 20%;
  border-right:1px solid #D1D1D1;
}
.post-card:last-child{
  border:none;
}

.post-card-img{
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1100px){
  .background{
    top: 130px
  }
}

@media screen and (max-width: 765px){
  .md{
    display: none;
  }

  .sm{
    display: block;
    top: 0px;
  }

  .stages-card-text{
    padding-bottom: 40px;
  }

  .post-card:last-child{
    border-bottom:1px solid #D1D1D1 ;
  }

  .outer-row{
    height: 480px;
    margin-bottom: 333px ;
    margin-top: 0px;
  }

  .post-row{
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    border: none;
  }

  .post-card{
    width: 100vw;
    height: 20%;
    padding: 48px 20px;
    border-right:none;
    border-bottom: 1px solid #D1D1D1;
  }

  .post-card:first-child{
    border-top: 1px solid #D1D1D1;
  }
}
</style>