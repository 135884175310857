<template>
  <div class="search-field">
    <a-typography-text class="font-raleway input-label">
      {{ $t('trackingSection.tracking_number') }}
    </a-typography-text>
    <a-input
      :value="code"
      :placeholder="$t('trackingSection.enter_tracking_number')"
      size="large"
      class="search-field_input"
      :disabled="loading"
      @input="onInput"
      @press-enter="redirectToTracking"
    >
      <template #suffix>
        <a-button
          type="primary"
          size="large"
          color="primary"
          :icon="h(SearchOutlined)"
          :loading="loading"
          :disabled="!isValid"
          @click="redirectToTracking"
        />
      </template>
    </a-input>
    <div class="help-message">
      <span>{{ helpMessage }}</span>
    </div>
    <a-typography-link
      type="danger"
      strong
      underline
      class="font-sans font-16"
      @click="onCopy"
    >
      <span class="flex align-center copy-link">
        <copy-icon class="mr-2" color='primary' /> 
        {{ $t('links.copy_tracking_link') }}
      </span>
    </a-typography-link>
  </div>
</template>

<script setup>
import { h } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import CopyIcon from "@/components/images/CopyIcon.vue";
</script>

<script>
import { message } from "ant-design-vue";

export default {
  name: "SearchField",
  props: {
    code: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['on-track', 'update:code'],
  data () {
    return {
      helpMessage: '',
      isValid: false,
    }
  },
  watch: {
    code: function () {
      this.validateCode();
    }
  },
  created() {
    this.validateCode();
  },
  methods: {
    validateCode() {
      this.isValid = false;
      if (this.code.length < 2) {
        this.helpMessage = '';
      }
      else if (!(/^p{2}/i.test(this.code))) {
        this.helpMessage = this.$t('messages.track_number_must_start_with_pp');
      }
      else if (this.code.length < 15) {
        this.helpMessage = this.$t('messages.track_number_must_be_15_characters_long');
      }
      else if (this.code.length > 15) {
        this.helpMessage = this.$t('messages.track_number_too_long');
      }
      else{
        this.isValid = true;
        this.loading?
        this.helpMessage = '' :
        this.helpMessage = this.$t('messages.press_enter_or_search');
      }
    },
    onCopy(){
      navigator.clipboard.writeText(this.code)
      .then(() => {
        message.success('Track copied to clipboard');
      })
      .catch(err => {
        console.error(err);
        message.error('Failed to copy address');
      });
    },
    onInput(val) {
      this.$emit("update:code", val.target.value);
    },
    redirectToTracking() {
      if (!this.loading && this.isValid) {
        this.helpMessage = "";
        this.$emit("on-track")
      }
    }
  }
}
</script>

<style scoped>
.search-field {
  width: 100%;
}

.search-field_input {
  margin: 4px 0 4px 0;
  border-radius: 12px;
}

.input-label{
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #07011F;
}

.help-message{
  height: 17px;
  padding-left: 12px;
  width: 100%;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color:#FE999B;
}

.copy-link{
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #fc0004;
}
</style>