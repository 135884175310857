import { createApp } from 'vue';

import { createI18n, useI18n } from 'vue-i18n';
import { languages } from './i18n';
import { defaultLocale } from './i18n';

import Antd from 'ant-design-vue';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/styles/global.css';
const messages = Object.assign(languages);

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
  warnHtmlMessage: false,
});

createApp(App, {
  setup: () => {
    const { t } = useI18n();
    return { t };
  },
})
  .use(router)
  .use(i18n)
  .use(Antd)
  .use(store)
  .mount('#app');
