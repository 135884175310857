<template>
  <a-layout-header class="header-wrapper flex align-center">
    <a-row class="main-menu flex align-center justify-space-between flex-no-wrap">
      <a-col class="header-logo px-3">
        <router-link
        :to="`/${$route.params.lang}/`"
          class="flex align-center"
        >
          <img
            src="@/assets/images/PP_Logo.svg"
            alt="Logo"
          >
        </router-link>
      </a-col>
      <a-col class="header-menu flex align-center justify-space-between">
        <router-link
        :to="`/${$route.params.lang}/track/`"
          class="menu-link"
        >
        {{ $t('links.track_parcel') }}
        </router-link>
        <!-- <router-link
        :to="`/${$route.params.lang}/calculator/`"
          class="menu-link"
        >
        {{ $t('links.calculate_delivery') }}
        </router-link> -->
        <router-link
        :to="`/${$route.params.lang}/business/`"
          class="menu-link"
        >
        {{ $t('links.for_business') }}
        </router-link>
        <a
          href="https://t.me/parcelsupport_bot"
          target="_blank"
          class="flex align-center justify-center"
        >
          <img
            src="@/assets/images/contact_icon.svg"
            alt="Contact"
            class="pr-2"
          >
          <span class="contact-link">{{ $t('links.contact_us') }}</span>
        </a>
        <div class="lang-switcher pl-2">
          <span class="px-1" :class="{ 'active': $i18n.locale === 'ru' }" @click="switchLang('ru')">RU</span>
          <span class="px-1" :class="{ 'active': $i18n.locale === 'en' }" @click="switchLang('en')">ENG</span>
        </div>
      </a-col>
      <a-col class="mobile-menu px-xs-0">
        <div class="lang-switcher pl-2">
          <span class="px-1" :class="{ 'active': $i18n.locale === 'ru' }" @click="switchLang('ru')">RU</span>
          <span class="px-1" :class="{ 'active': $i18n.locale === 'en' }" @click="switchLang('en')">ENG</span>
        </div>
        <a-dropdown :trigger="['click']">
          <a
            class="ant-dropdown-link"
            @click.prevent
          >
            <MenuOutlined style="color: #FC0004;" />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <router-link
                  :to="`/${$route.params.lang}/track/`"
                  class="px-2 menu-link"
                >
                {{ $t('links.track_parcel') }}
                </router-link>
              </a-menu-item>
              <a-menu-item key="1">
                <router-link
                  :to="`/${$route.params.lang}/calculator/`"
                  class="px-2 menu-link"
                >
                {{ $t('links.calculate_delivery') }}
                </router-link>
              </a-menu-item>
              <a-menu-item key="3">
                <router-link
                  :to="`/${$route.params.lang}/business/`"
                  class="px-2 menu-link"
                >
                {{ $t('links.for_business') }}
                </router-link>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3">
                <router-link
                  :to="`/${$route.params.lang}/contact/`"
                  class="flex align-center justify-start px-2 menu-link"
                >
                  <img
                    src="@/assets/images/contact_icon.svg"
                    alt="Contact"
                    class="mr-2"
                  >
                  <span class="contact-link">{{ $t('links.contact_us') }}</span>
                </router-link>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
</template>

<script setup>
    import { MenuOutlined } from '@ant-design/icons-vue';
</script>

<script>
export default {
  name: 'HeaderMain',
  data() {
    return {
    }
  },
  methods: {
    switchLang(lang) {
      const currentRoute = this.$route.path;
      const newRoute = currentRoute.replace(/^\/[a-z]{2}/, `/${lang}`);
      this.$i18n.locale = lang;
      this.$router.push(newRoute);
    }
  }
}
</script>

<style scoped>
.header-wrapper{
  background-color: #fff;
  height: 80px;
  border-bottom: 1px solid #D1D1D1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
}

.main-menu{
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;
}

.header-logo a img{
  max-width: 162px;
  width: 12vw;
}

.header-menu {
  max-width: 600px;
  width: 100%;
}

.header-menu a, .menu-link{
  text-decoration: none;
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #07011F;
  cursor: pointer;
  white-space: nowrap;
}

.header-menu a:hover{
  font-weight: 400;
  color: #FC0004;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.contact-link{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #FC0004;
}

.lang-switcher{
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #DDDDDD;
  cursor: pointer;
}

.lang-switcher span:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 600;
}

.active{
  color: #07011F; 
}

.mobile-menu{
  display: none;
}

@media screen and (max-width: 1000px) and (min-width: 841px){
  .header-menu a, .contact-link, .lang-switcher{
    font-size: 12px;
    line-height: 20px;
  }

  .header-menu a img{
    width: 20px;
    height: 20px;
  }

}

@media screen and (max-width: 840px) and (min-width: 765px){
  .header-menu a, .contact-link, .lang-switcher{
    font-size: 10px;
    line-height: 16px;
  }
  .header-menu a img{
    width: 15px;
    height: 15px;
  }

}

@media screen and (max-width: 764px){
  .main-menu{
    padding: 0 16px;
  }

  .mobile-menu{
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0;
  }
  .header-menu{
    display: none !important;
    visibility: hidden;
  }

  .header-logo a img{
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .header-wrapper{
    padding: 0;
  }
}
</style>