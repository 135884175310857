<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#FC0004',
      },
    }"
  >
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const {t} = useI18n ({useScoupe: 'global'});
</script>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app{
  overflow-x: hidden;
}
</style>
