<template>
  <section id="business" style="scroll-margin-top: 100px !important;">
    <a-row class="section-row business flex flex-column">
      <img 
        src="@/assets/images/Oval.svg" 
        alt="Oval" 
        class="background"
      >
      <h4 class="business-title">
        {{ $t('businessSection.title1') }}
      </h4>
      <a-flex
        justify="center"
        align="top"
      >
        <a-button
          type="primary"
          class="deliver-doc-btn btn btn-black-light mb-10"
          @click="$router.push('/business')"
        >
          {{ $t('links.for_business') }}
        </a-button>
      </a-flex>
      <a-row>
        <a-col
          :md="{ span: 16, order: 1 }"
          :sm="{ span: 24, order: 1 }"
          :xs="{span: 24, order: 1}"
        >
          <a-row class="mb-lg-5">
            <a-col
              :md="{ span: 11, order: 1 }"
              :sm="{ span: 24, order: 1 }"
              :xs="{span: 24, order: 1}"
              class='flex justify-center'
            >
              <div class="card flex justify-space-between align-center mr-5">
                <span class="card-title pt-7" v-html="$t('businessSection.termsFrom10Days')"></span>
                <img
                  src="@/assets/images/flash.svg"
                  alt="flash1"
                  class="bar-img flash pb-8 pt-1"
                >
              </div>
            </a-col>
            <a-col
              :md="{ span: 13, order: 1 }"
              :sm="{ span: 24, order: 2 }"
              :xs="{span: 24, order: 2}"
              class='flex justify-center'
            >
              <div class="card flex justify-space-between align-center h100">
                <span class="card-title" v-html="$t('businessSection.telegramBot')"></span>
                <img
                  src="@/assets/images/plane.svg"
                  alt="plane1"
                  class="bar-img plane"
                >
              </div>
            </a-col>
            <a-col
              :xs="{span: 24, order: 2}"
              class='flex justify-center'
            >
              <div class="card flex justify-space-between align-center h100 small-card">
                <span class="card-title" v-html="$t('businessSection.dedicatedManager')"></span>
                <img
                  src="@/assets/images/messages2.svg"
                  alt="messages2"
                  class="bar-img message"
                >
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col
              :md="{ span: 12, order: 1 }"
              :sm="{ span: 24, order: 3 }"
              :xs="{span: 24, order: 3}"
              class='flex justify-center'
            >
              <div class="card mr-5 h100">
                <span class="card-title parcel-title mt-7" v-html="$t('businessSection.specialTariff')"></span>
                <img
                  src="@/assets/images/parcel.svg"
                  alt="parcel1"
                  class="bar-img parcel-img"
                >
              </div>
            </a-col>
            <a-col
              :md="{ span: 12, order: 1 }"
              :sm="{ span: 24, order: 4 }"
              :xs="{span: 24, order: 4}"
              class='flex justify-center'
            >
              <div class="card flex justify-space-between align-center">
                <span class="card-title pb-4" v-html="$t('businessSection.personalService')"></span>
                <img
                  src="@/assets/images/star-big.svg"
                  alt="star-big1"
                  class="bar-img star py-9"
                >
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col
          :md="{ span: 8, order: 2 }"
          :sm="{ span: 24, order: 2 }"
          class="pl-5 big-card"
        >
          <div class="card flex flex-column justify-space-between align-start h100">
            <span class="card-title mt-13" v-html="$t('businessSection.dedicatedManager')"></span>
            <img
              src="@/assets/images/messages1.svg"
              alt="messages1"
              class="bar-img message mb-13"
            >
          </div>
        </a-col>
      </a-row>
      <a-row
        justify="center"
        align="top"
      >
        <a-button
          type="primary"
          class="btn-large deliver-doc-advantages-btn mt-sm-7 mt-lg-15 mt-md-7"
          @click="redirectTo('https://t.me/parcelsupport_bot')"
        >
          {{ $t('links.get_consultation') }}
        </a-button>
      </a-row>
    </a-row>
    <a-row class="section-row business flex flex-column">
      <h4 class="business-title">
        {{ $t('businessSection.title2') }}
      </h4>
      <a-flex
        justify="center"
        align="top"
      >
        <a-button
          type="primary"
          class="deliver-doc-btn btn btn-black-light mb-10"
          @click="$router.push('/business')"
        >
          {{ $t('links.for_business') }}
        </a-button>
      </a-flex>
      <a-row>
        <a-col 
          :md="{ span: 9, order: 1 }"
          :sm="{ span: 24, order: 1 }"
          :xs="{span: 24, order: 1}"
          class=' flex align-center justify-center'
        >
          <div class='tariff-tile'>
            <span class='tariff-card-title' v-html="$t('businessSection.dedicatedClientManager')"></span>
            <img
              src="@/assets/images/manager-back.avif"
              alt="flash1"
              class="h100 card-img lg-img pr-5"
            >
            <img
              src="@/assets/images/manager-back-horizontal.avif"
              alt="flash1"
              class="card-img sm-img pb-5"
            >
          </div>
        </a-col>
        <a-col 
          :md="{ span: 5, order: 1 }"
          :sm="{ span: 24, order: 1 }"
          :xs="{span: 24, order: 1}"
          class='flex align-center justify-center' 
        >
          <div class='tariff-tile'>
            <span class='tariff-card-title' v-html="$t('businessSection.b2bCabinet')"></span>
            <img
              src="@/assets/images/b2b-back.avif"
              alt="flash1"
              class="card-img lg-img"
            >
            <img
              src="@/assets/images/b2b-horizontal.avif"
              alt="flash1"
              class="card-img sm-img pb-5"
            >
          </div>
        </a-col>
        <a-col
          :md="{ span: 10, order: 1 }"
          :sm="{ span: 24, order: 1 }"
          :xs="{span: 24, order: 1}"
        >
          <a-row
            :span="24" 
            :order="1"
            class='flex align-center justify-center'
          >
            <div class='tariff-tile'>
              <span class='tariff-card-title px-lg-5 px-md-5 py-xs-5' v-html="$t('businessSection.individualPrice')"></span>
              <img
              src="@/assets/images/individual-price-bacground.avif"
              alt="flash1"
              class="card-img lg-img pl-5"
            >
            <img
              src="@/assets/images/individual-price-bacground-small.avif"
              alt="flash1"
              class="card-img sm-img mb-5"
            >
            </div>
          </a-row>
          <a-row
            :span="24" 
            :order="1"
            class=' flex align-center justify-center'
          >
            <div class='tariff-tile'>
              <span class='tariff-card-title px-lg-5 px-md-5' v-html="$t('businessSection.integrationWMS')"></span>
              <img
              src="@/assets/images/wms-card-background.avif"
              alt="flash1"
              class="card-img mt-5 pl-5 lg-img"
            >
            <img
              src="@/assets/images/wms-card-background-small.avif"
              alt="flash1"
              class="card-img mb-5 sm-img"
            >
            </div>
          </a-row>
        </a-col>
      </a-row>
      <a-row
        justify="center"
        align="top"
      >
        <a-button
          type="primary"
          class="btn-large deliver-doc-advantages-btn mt-sm-2 mt-lg-15 mt-md-7"
          @click="redirectTo('https://t.me/parcelsupport_bot')"
        >
          {{ $t('links.get_consultation') }}
        </a-button>
      </a-row>
    </a-row>
  </section>
</template>

<script>
export default {
  name: "BusinessSection",
  methods: {
    redirectTo(link) {
      window.open(link, '_blank') ;
    }
  }
}
</script>

<style scoped>
.business{
  margin-top: 180px;
  position: relative;
}

.background{
  position: absolute;
  top: -125px;
  left: -175px;
  width: 70%; 
  height: auto;
}

.business-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: center;
  color: #000;
}

.small-card{
  display: none;
}

.card{
  position: relative;
  background-color: #161616;
  border-radius:40px;
  padding-left: 40px;
  padding-right: 25px;
  max-width: 450px;
  width: 100%;
}

.card-title{
  color: #fff;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: left;
}

.parcel-title{
  float:left;
}

.parcel-img{
  position:absolute; 
  right: 25px; 
  bottom: 15px; 
  width: 40%;
}

.message{
  width: 89.5%;
}

.star{
  width: 46%;
}

.plane{
  width: 47%;
}

.flash{
  width: 46%;
}

.tariff-tile{
  position:relative; 
  height: 100%; 
  max-width: 450px;
}

.tariff-card-title{
  position: absolute;
  bottom: 32px;
  left: 40px;
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 29.96px;
  text-align: left;
  color:#fff;
}

.card-img{
  max-width: 100%;
}

.sm-img{
  display: none;
}

@media screen and (max-width: 1100px) {
  .card-title{
    font-size: 18px;
    line-height: 24px;
  }

  .card{
    padding-left: 30px;
    padding-right: 15px;
  }

  .tariff-card-title{
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 960px) {
  .card-title{
    font-size: 16px;
    line-height: 18px;
  }

  .card{
    padding-left: 25px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 820px) {
  .card-title{
    font-size: 14px;
    line-height: 16px;
  }

  .card{
    padding-left: 20px;
    padding-right: 10px;
  }

  .tariff-card-title{
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .business{
    margin-top: 100px;
  }

  .background{
    position: absolute;
    top: -100px;
    left: -185px;
    width: 100%; 
    height: auto;
  }
  .card-title{
    font-size: 20px;
    line-height: 27px;
  }

  .card{
    padding-left: 40px;
    padding-right: 25px;
  }
  .big-card{
    display: none;
  }

  .small-card{
    display: flex;
  }

  .card{
    margin: 10px 0px;
    height: 210px;
  }

  .parcel-img{
    height: 60%;
    width: auto;
  }

  .message{
    width: auto;
    height: 76%;
  }

  .star{
    width: auto;
    height: 100%;
  }

  .plane{
    height: 55%;
    width: auto;
  }

  .flash{
    height: 100%;
    width: auto;
  }

  .lg-img{
    display: none;
  }

  .sm-img{
    display: block;
  }

  .tariff-card-title{
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 20px
  }
}
</style>