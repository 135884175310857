<template>
  <a-flex align='center' class='message-wrapper'>
    <img src="@/assets/images/Error.svg" alt='Error' width="48px" height="41px" class='mr-4' />
    <span class='message-text'>{{message}}</span>
    </a-flex>
</template>

<script>
export default {
  name: "errorMessage",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.message-wrapper{
  max-width: 600px;
}

.message-text{
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #07011F;
}
</style>