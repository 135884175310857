<template>
  <layout-page 
  :anchors="[{ path: 'not_found', label: $t('links.page_not_found') }]"
  :activeSection='"not_found"'
    >
    <a-flex vertical align='center' justify='center' style="margin: 160px 17px">
      <img src="@/assets/images/404.svg" alt="404" />
      <h2 class='title mt-5'>{{ $t('links.page_not_found') }}</h2>
      <a-button type="primary" href="/" class='mt-7 btn btn-large'>{{ $t('links.go_main_page') }}</a-button>
    </a-flex>
  </layout-page>
</template>

<script>
import LayoutPage from "@/components/LayoutPage.vue";

export default {
  name: 'notFound',
  components: {
    LayoutPage,
  },
}
</script>

<style scoped>
.title{
  font-family: Raleway;
  font-size: 48px;
  font-weight: 400;
  line-height: 56.35px;
  text-align: center;
  color: #07011F;

}
</style>
