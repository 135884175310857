<template>
  <section>
    <a-row class='section-row about-section' >
      <div class='about-info'>
        <h3 class='about-title mb-9'>{{ $t('aboutSection.aboutUsTitle') }}</h3>
        <p class='about-text'>{{ $t('aboutSection.aboutUsText') }}</p>
        <a-row class='btn-section mt-9'>
          <a-col 
            :md="{ span: 10, order: 1 }"
            :sm="{ span: 20, order: 1 }"
            :xs="{span: 20, order: 1}"
          >
            <a-button
              type="primary"
              disabled
              :icon="h(EnvironmentOutlined)"
              class="deliver-doc-btn btn w100 mb-md-5 mb-sm-5"
            >
              {{ $t('links.wilmington') }}
            </a-button>
          </a-col>
          <a-col
            :md="{ span: 10, offset:2, order: 2 }"
            :sm="{ span: 20, order: 2 }"
            :xs="{span: 20, order: 2}"
          >
            <a-button
              type="primary"
              disabled
              :icon="h(EnvironmentOutlined)"
              class="deliver-doc-btn btn w100"
            >
              {{ $t('links.houston') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <img
        src="@/assets/images/Rectangle 5403.avif"
        alt="flash1"
        class="bar-img info-img"
      >
      <a-row class="creepting-row">
        <div class="creepting-lines">
          <CreepingLine
            text="KAZAKHSTAN USA RUSSIA UZBEKISTAN"
            :rotation-deg="5.62"
            background-color="#07011F"
            spin="left"
          />
          <CreepingLine
            text="PARCELPOINT"
            :rotation-deg="-4.68"
            background-color="#FC0004"
            spin="right"
          />
        </div>
      </a-row>
    </a-row>
    <a-row class='values-row row-top'>
      <a-divider class='top divider' />
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex justify-center values-card'
      >
        <div class='values-card-container'>
          <h4 class='values-title' v-html="$t('aboutSection.ourValuesTitle')"></h4>
        </div>
        <a-divider class='bottom divider sm' />
      </a-col>
      <a-col
        :md="{ span: 8 }"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex justify-center values-card middle'
      >
        <div class='values-card-container'>
          <h5 class='values-card-title'>
            <img src="@/assets/images/Ellipse_icon.svg" class="mr-2" />
            {{ $t('aboutSection.reliabilityTitle') }}
          </h5>
            <p>{{ $t('aboutSection.reliabilityText') }}</p>
        </div>
        <a-divider class='bottom divider sm' />
      </a-col>
      <a-col
        :md="{ span: 8}"
        :sm="{ span: 24 }"
        :xs="{span: 24}"
        class='flex justify-center values-card'
      >
        <div class='values-card-container'>
          <h5 class='values-card-title'>
            <img src="@/assets/images/Ellipse_icon.svg" class="mr-2" />
            {{ $t('aboutSection.professionalismTitle') }}
          </h5>
            <p>{{ $t('aboutSection.professionalismText') }}</p>
        </div>
      </a-col> 
    </a-row>
    <a-row class='values-row' style="margin-bottom: 100px;">
      <a-divider class='top divider' />
      <a-divider class='bottom divider' />
      <a-col
        :md="{ span: 8}"
        :sm="{ span: 24}"
        :xs="{span: 24}"
        class='flex justify-center values-card'
      >
        <div class='values-card-container'>
          <h5 class='values-card-title'>
            <img src="@/assets/images/Ellipse_icon.svg" class="mr-2" />
            {{ $t('aboutSection.processFlexibilityTitle') }}
          </h5>
            <p>{{ $t('aboutSection.processFlexibilityText') }}</p>
        </div>
        <a-divider class='bottom divider sm' />
      </a-col>
      <a-col
        :md="{ span: 8}"
        :sm="{ span: 24}"
        :xs="{span: 24}"
        class='flex justify-center values-card middle'
      >
        <div class='values-card-container'>
          <h5 class='values-card-title'>
            <img src="@/assets/images/Ellipse_icon.svg" class="mr-2" />
            {{ $t('aboutSection.opennessToChangeTitle') }}
          </h5>
            <p>{{ $t('aboutSection.opennessToChangeText') }}</p>
        </div>
        <a-divider class='bottom divider sm' />
      </a-col>
      <a-col
        :md="{ span: 8}"
        :sm="{ span: 24}"
        :xs="{span: 24}"
        class='flex justify-center values-card'
      >
      <div class='values-card-container'>
        <h5 class='values-card-title'>
          <img src="@/assets/images/Ellipse_icon.svg" class="mr-2" />
          {{ $t('aboutSection.determinationTitle') }}
        </h5>
          <p>{{ $t('aboutSection.determinationText') }}</p>
      </div>
      </a-col>
    </a-row>
    <a-row class='solutions-row mx-4'>
      <h4 class='solutions-title mb-10' v-html="$t('aboutSection.solutionsTitle')"></h4>
      <img 
        src="@/assets/images/Oval-1.svg" 
        alt="Oval" 
        class="background"
      >
    </a-row>
    <a-row class='solutions-title-row' justify='center'>
      <a-divider class='top divider crane' />
      <img src="@/assets/images/crane.svg" class="crane-img" />
    </a-row>
    <a-row class='solutions-card-row' style="margin-bottom: 100px;">
      <a-col
      :md="{ span: 8}"
      :sm="{ span: 24}"
      :xs="{span: 24}"
      class='flex justify-center pl-md-2 pl-lg-2'
      >
      <a-flex class='solution-card' vertical justify='start' gap="middle">
        <img src="@/assets/images/check-icon.svg" alt='icon' class='mb-2' width="24px"/>
        <h5 class='solution-card-title'>{{ $t('aboutSection.thoroughCheckTitle') }}</h5>
        <p class='solution-card-text'>{{ $t('aboutSection.thoroughCheckText') }}</p>
      </a-flex>
    </a-col>
    <a-col
    :md="{ span: 8}"
    :sm="{ span: 24}"
    :xs="{span: 24}"
    class='flex justify-center px-md-2 px-lg-2 my-sm-5 my-md-5'
    >
      <a-flex class='solution-card' vertical justify='start' gap="middle">
        <img src="@/assets/images/safety-icon.svg" alt='icon' class='mb-2' width="24px"/>
        <h5 class='solution-card-title'>{{ $t('aboutSection.safetyTitle') }}</h5>
        <p class='solution-card-text'>{{ $t('aboutSection.safetyText') }}</p>
      </a-flex>
    </a-col>
    <a-col
    :md="{ span: 8}"
    :sm="{ span: 24}"
    :xs="{span: 24}"
      class='flex justify-center pr-md-2 pr-lg-2'
    >
      <a-flex class='solution-card' vertical justify='start' gap="middle">
        <img src="@/assets/images/reliability-icon.svg" alt='icon' class='mb-2' width="24px"/>
        <h5 class='solution-card-title'>{{ $t('aboutSection.reliabilitySolutionTitle') }}</h5>
        <p class='solution-card-text'>{{ $t('aboutSection.reliabilitySolutionText') }}</p>
      </a-flex>
    </a-col>
    </a-row>
  </section>
</template>

<script setup>
import { h } from 'vue';
import { EnvironmentOutlined } from '@ant-design/icons-vue';
</script>

<script>
import CreepingLine from '@/components/CreepingLine.vue'
export default {
  name: 'AboutSection',
  components: {
    CreepingLine,
  },
  methods: {
    redirectTo(link) {
      window.open(link, '_blank') ;
    }
  }
}
</script>

<style scoped>
.about-section{
  margin-top: 177px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
}

.about-info{
  position: absolute;
  left: 12px;
  padding: 60px 48px 60px 0px;
  z-index: 2;
  max-width: 632px;
  border-radius: 0 0 36px 0;
  background-color: #fff;
}

.about-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #07011F;
}

.about-text{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #07011F;
}

.info-img{
  position: absolute;
  right: -44px;
  top: 40px;
  z-index: 1;
}

.deliver-doc-btn{
  cursor: default;
  background-color: #FC0004;
  color: #fff;
  border-color: #FC0004;
}

.deliver-doc-btn:hover{
  background-color: #FC0004;
}

.creepting-row{
  position: absolute;
  right: 0;
  top:370px;
  z-index: 2;
  left: 50%; 
  transform: translateX(-50%); 
  width: 100vw; 
}

.creepting-lines{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  width: 100%;
  z-index: 7;
}

.row-top{
  margin-top: 350px;
}

.values-row{
  min-height: 300px;
  height: 100%; 
  position: relative;
}

.divider{
  height: 1px; 
  background-color: #D1D1D1;
  position: absolute; 
  left: 50%; 
  transform: translateX(-50%); 
  width: 100vw; 
  margin: 0px;
}

.top{
  top:0px; 
}

.bottom{
  bottom: 0px;
}

.sm{
  display: none;
}

.values-card-container{
  max-width: 274px; 
  width: 100%;
  margin-left: 18px ;
  margin-right: 10px ;
}

.values-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color:#07011F;
}

.values-card{
  padding-top:80px;
}

.middle{
  border-left: 1px solid #D1D1D1; 
  border-right: 1px solid #D1D1D1;
}

.values-card-title{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.48px;
  text-align: left;
  color:#07011F;
}

.solutions-title-row{
  position: relative;
}

.solutions-row{
  position: relative;
}

.background{
  position: absolute;
  top: -93px;
  right: -203px;
  width: 53%; 
  height: auto;
}

.solutions-card-row{
  position: relative;
  top:-7px;
}

.crane::after{
  display: block;
  content: '';
  width: 60px;
  height: 4px;
  border-radius: 4px;
  background-color: #161616;
  position: absolute;
  top:-4px; 
  left: 50%; 
  transform: translateX(-64%);
}

.solutions-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color:#07011F;
}

.solution-card{
  max-width: 327px;
  width: 100%;
  min-height: 283px;
  padding: 32px 10px 0px 32px;
  border-radius: 20px;
  background: linear-gradient(240.44deg, #0B0B0B 42.06%, #230D0D 65.15%);
}

.solution-card-title{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.48px;
  text-align: left;
  color: #E5E5E5;
}

.solution-card-text{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #E5E5E5;
  max-width: 263px;
}

@media screen and (max-width: 980px) {
  .about-section{
    height: auto;
  }

  .about-info{
    position: static;
  }

  .info-img {
    position: static;
  }

  .creepting-row{
    top: 780px;
  }
}

@media screen and (max-width: 765px){
  .sm{
    display: block;
  }
  
  .values-card{
    padding-top:32px;
    padding-bottom:32px;
  }

  .row-top{
    margin-top: 150px;
  }

  .background{
    width: 110%;
    top: -155px
  }

  .middle{
    border: none;
  }

  .about-section{
    margin-top: 0;
  }
}

</style>