<template>
  <a-flex vertical class='w100'>
    <h5 class='info-title mb-6'>{{ title }}</h5>
    <a-flex justify='space-between' v-for='(item, index) in data' :key='index'>
      <p class='label'>{{ item.label }}</p>
      <p class='value'>{{ item.value }}</p>
    </a-flex>
  </a-flex>
</template>

<script>
export default {
  name: "AboutParcel",
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
}
</script>

<style scoped>
.info-title{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.48px;
  text-align: left;
  color: #07011F;
}

.label{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717171;
}

.value{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #07011F;
}
</style>