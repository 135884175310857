<template>
  <div
    class="infinite-marquee flex align-center"
    :style="`transform: rotate(${rotationDeg}deg);
    background-color:${backgroundColor};`"
  >
    <div class="message-wrapper">
      <div
        v-for="(item, index) in messages"
        :key="index"
        class="message flex align-center justify-center"
        :class="{'move-right': spin === 'right', 'move-left': spin === 'left'}"
      >
        <div
          v-for="(el, i) in item"
          :key="i"
          class="flex align-center justify-center"
        >
          <span style="padding: 24px">{{ el }}</span>
          <img
            src="@/assets/images/Ellipse 378.svg"
            class="icon"
            alt="icon"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreepingLine',
  props: {
    text: {
      type: String,
      required: true,
      default: 'Creeping Line'
    },
    backgroundColor: {
      type: String,
      default: "transparent"
    },
    rotationDeg: {
      type: Number,
      required: true,
      default: 0
    },
    spin: {
      type: String,
      required: true,
      default: 'right'
    }
  },
  computed: {
    messages() {
      const words = this.text.split(' ');
      return Array.from({length: 9}, () => (words));
    }
  }
}
</script>

<style scoped>

.infinite-marquee {
  position: absolute;
  left: -350px;
  right: -750px;
  margin-top: 150px;
  overflow: hidden;
  background-color: #FC0004;
  height: 91px;
  z-index: 99;
}

.message-wrapper {
  display: flex;
}

.message {
  white-space: nowrap;
  font-family: Raleway,sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0.07em;
  text-align: left;
  color: #fff;
  flex-shrink: 0;
}

.move-right {
  animation: marquee-right 5s linear infinite;
}

.move-left {
  animation: marquee-left 9s linear infinite;
}

.icon {
  width: 12px;
  height: auto;
}

@keyframes marquee-left {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


@keyframes marquee-right {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@media screen and (max-width: 1150px) {
  .infinite-marquee {
    margin-top: 100px
  }
}

@media (max-width: 600px) {
  .infinite-marquee {
    height: 71px;
  }
}

</style>