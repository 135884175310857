<template>
  <section
    id="segments"
    class="segments-section"
  >
    <a-row class="section-row dark-row flex-column">
      <div class='dark-background'></div>
        <h3 class='section-title' v-html="$t('segmentsSection.title')"></h3>
      <a-flex class='card-row' justify='space-between'>
        <a-flex class='segment-card flex-column'>
          <img src="@/assets/images/bayer-img.png" alt="card-img" width="120px" class='mb-3'/>
          <h4 class='card-subtitle'>{{ $t('segmentsSection.buyersAndVendors.title') }}</h4>
          <ul class="pl-5">
            <li class='card-list-item'>{{ $t('segmentsSection.buyersAndVendors.item1') }}</li>
            <li class='card-list-item'>{{ $t('segmentsSection.buyersAndVendors.item2') }}</li>
            <li class='card-list-item'>{{ $t('segmentsSection.buyersAndVendors.item3') }}</li>
          </ul>
          <a-button
            type="primary"
            class="sent-btn mt-9"
            @click="redirectTo('https://t.me/parcelsupport_bot')"
          >
            {{ $t('links.connect_with_us') }}
            <img src="@/assets/images/Button-sent.svg" alt="sent" width="40px" class='btn-icon'/>
          </a-button>
        </a-flex>
        <a-flex class='segment-card flex-column '>
          <img src="@/assets/images/E-commerce.png" alt="card-img" width="120px" class='mb-3'/>
          <h4 class='card-subtitle'>{{ $t('segmentsSection.ecommerce.title') }}</h4>
          <ul class="pl-5">
            <li class='card-list-item'>{{ $t('segmentsSection.ecommerce.item1') }}</li>
            <li class='card-list-item'>{{ $t('segmentsSection.ecommerce.item2') }}</li>
            <li class='card-list-item'>{{ $t('segmentsSection.ecommerce.item3') }}</li>
          </ul>
          <a-button
            type="primary"
            class="sent-btn mt-9"
            @click="redirectTo('https://t.me/parcelsupport_bot')"
          >
            {{ $t('links.connect_with_us') }}
            <img src="@/assets/images/Button-sent.svg" alt="sent" width="40px" class='btn-icon'/>
          </a-button>
        </a-flex>
      </a-flex>
      <div class="creepting-lines">
        <CreepingLine
          text="PARCELPOINT"
          :rotation-deg="0"
          background-color="#FC0004"
          spin="right"
        />
      </div>
    </a-row>
    <a-row class="section-row advantages-row">
      <h4 class="advantages-title mb-6">{{ $t('segmentsSection.advantages.title') }}</h4>
      <p class="advantages-text mb-10">{{ $t('segmentsSection.advantages.text') }}</p>
      <img src="@/assets/images/Advantages-img.avif" alt="advantages" class="bar-img" />
    </a-row>
  </section>
</template>

<script>
import CreepingLine from '@/components/CreepingLine.vue'
export default {
  name: "segments",
  components: {
    CreepingLine
  },
  methods: {
    redirectTo(link) {
      window.open(link, '_blank') ;
    }
  }
}
</script>

<style scoped>
.segments-section{
  position: relative;
  scroll-margin-top: 80px !important; 
  margin-bottom: 180px; 
  margin-top:180px;
}

.dark-row{
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
}

.section-title{
  position: relative;
  z-index: 2;
  margin-bottom: 54px;
  font-family: Raleway;
  font-size: 64px;
  font-weight: 500;
  line-height: 75.14px;
  text-align: left;
  color: #FFFFFF;
}

.dark-background{
  background-color: #1B1B1B;
  position: absolute;
  z-index: 1;
  top: 0px;
  height: 100%; 
  left: 50%; 
  transform: translateX(-50%); 
  width: 100vw;
}

.card-row{
  position: relative;
  z-index: 2;
}

.segment-card{
  max-width: 490px;
  width: 100%;
  align-items: flex-start;
  padding: 60px;
  border: 1px solid #fff;
  border-radius: 40px;
  color: #fff;
  margin-top: 20px;
  margin-right: 20px;
}

.segment-card:last-child{
  margin-right: 0px;
}

.card-subtitle{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #fff;
}

.card-list-item{
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.sent-btn{
  position: relative;
  max-width: 210px;
  width: 100%;
  height: 56px;
  border-radius: 10px;
  padding-left: 16px;
  border: 1px solid #fff;
  background-color: #1B1B1B;
  color:#fff;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.sent-btn:hover{
  background-color: #fff;
  color: #1B1B1B;
  border: 1px solid #1B1B1B;
}

.btn-icon{
  position: absolute;
  right: 7px;
  top: 7px;
}

.creepting-lines{
  position: absolute;
  bottom: -167px;
  width: 100%;
  z-index: 7;
  overflow: hidden;
  height: 241px;
  left: 50%; 
  transform: translateX(-50%); 
  width: 100vw;
}

.advantages-row{
  margin-top: 350px;
  color: #07011F;
}

.advantages-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #07011F;
}

.advantages-text{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

@media screen and (max-width: 850px){
  .card-row{
    flex-direction: column;
    align-items: center;
  }
  .segment-card{
    margin-right: 0px;
  }

  .section-title{
    font-size: 42px;
    line-height: 48px;
  }
}

@media screen and (max-width: 765px) {
  .section-title{
    font-size: 32px;
    line-height: 38px;
  }

  .dark-row{
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .creepting-lines{
    position: absolute;
    bottom: -141px;
    width: 100%;
    z-index: 7;
    overflow: hidden;
    height: 241px;
    left: 50%; 
    transform: translateX(-50%); 
    width: 100vw;
  }

  .segments-section{
    margin-bottom: 100px; 
    margin-top: 100px;
  }

  .advantages-row{
    margin-top: 200px;
  }
}
</style>