<template>
  <layout-page
    :anchors="[{ path: 'track', label: $t('links.track_parcel') }]"
    :activeSection='activeSection'
    :title="$t('track_page.title')"
  >
    <section id="track">
      <a-row>
        <a-col
          :md="{ span: 14, order: 1 }"
          :sm="{ span: 24, order: 1 }"
          :xs="{ span: 24, order: 1 }"
        >
          <search-field
            v-model:code="trackCode"
            :loading="loading"
            class="mb-10"
            @on-track="onTrack"
          />
          <MessageError 
            v-if='errorMessage'
            :message="errorMessage"
          />
          <CityPoints
            v-if="points"
            :data="points"
          />
          <parcel-route
            v-if="routeStep"
            :step="routeStep"
            :city="currentCity"
          />
          <AboutParcel
            v-if="about.length > 0"
            class="mt-13"
            :title="$t('track_page.about_delivery')"
            :data="about"
          />
          <!--          TODO: we do not get date from server for this block -->
          <!--          <AboutParcel-->
          <!--            class="mt-13"-->
          <!--            title="Parcel information"-->
          <!--            :data="info"-->
          <!--          />-->
        </a-col>
        <a-col
          v-if="statuses.length > 0"
          :md="{ span: 7, offset: 3, order: 2 }"
          :sm="{ span: 24, order: 2 }"
          :xs="{ span: 24, order: 2 }"
        >
          <DeliveryHistory :statuses="statuses" />
        </a-col>
      </a-row>
    </section>
  </layout-page>
</template>

<script>
import {trackParcel} from "@/api/newPublicApi";
import LayoutPage from "@/components/LayoutPage.vue";
import SearchField from "@/components/trackParcel/SearchField.vue";
import ParcelRoute from "@/components/trackParcel/ParcelRoute.vue";
import AboutParcel from "@/components/trackParcel/AboutParcel.vue";
import DeliveryHistory from "@/components/trackParcel/DeliveryHistory.vue";
import CityPoints from "@/components/trackParcel/CityPoints.vue";
import MessageError from "@/components/trackParcel/MessageError.vue";
import {RouteStepEnum} from "@/constants/RouteStep";

function transformDate(input, addDays = 0) {
  const date = new Date(input);
  date.setDate(date.getDate() + addDays);
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const routeStepDescriptions = {
  [RouteStepEnum.DELIVERED]: "Received",
  [RouteStepEnum.DELIVERY]: "Delivery",
  [RouteStepEnum.IN_TRANSIT]: "On Way",
  [RouteStepEnum.CREATED]: "Dispatch",
}

export default {
  name: "TrackParcel",
  components: {
    ParcelRoute,
    SearchField,
    LayoutPage,
    AboutParcel,
    DeliveryHistory,
    CityPoints,
    MessageError
  },
  data: () => ({
    activeSection: "track",
    trackCode: null,
    about: [],
    statuses: [],
    points: null,
    routeStep: null,
    currentCity: null,
    loading: false,
    errorMessage: null,
  }),
  created() {
    this.trackCode = this.$route.params.id;
    if (this.trackCode != null && this.trackCode !== "") {
      this.syncTrack();
    }
  },
  methods: {
    onTrack() {
      this.$router.push(`/${this.$route.params.lang}/track/${this.trackCode}/`);
      this.syncTrack();
    },
    syncTrack() {
      this.errorMessage = null;
      this.loading = true;
      trackParcel(this.trackCode)
          .then(res => {
            this.clearState();
            const statuses = res.statuses;

            // Resolve statuses
            this.statuses = statuses.map(it => ({
              status: it.status.replace(/_/g, " ").toLowerCase(),
              location: it.location,
              date: transformDate(it.date),
              description: it.description
            }));

            // Resolve points
            this.points = {
              from: res.from?.cityName,
              to: res.to?.cityName
            }

            // Resolve route step
            if (statuses.find(it => it.category === "LAST_MILE" && it.status === "DELIVERED")) {
              this.routeStep = RouteStepEnum.DELIVERED;
            } else if (statuses.find(it => it.category === "LAST_MILE")) {
              this.routeStep = RouteStepEnum.DELIVERY;
            } else if (statuses.find(it => it.category === "FIRST_MILE")) {
              this.routeStep = RouteStepEnum.IN_TRANSIT;
            } else {
              this.routeStep = RouteStepEnum.CREATED;
            }

            for (const status of statuses) {
              if (status.location) {
                this.currentCity = status.location;
                break;
              }
            }

            // Resolve parcel info
            const firstStatus = statuses[res.statuses.length - 1];
            if (firstStatus) {
              this.about = [
                {label: this.$t('track_page.status'), value: routeStepDescriptions[this.routeStep]},
                {label: this.$t('track_page.departure_date'), value: transformDate(firstStatus.date)},
                {label: this.$t('track_page.expected_delivery_date'), value: transformDate(firstStatus.date, 30)}
              ];
            }
          })
          .catch(e => {
            if (e.response?.status >= 500) {
              this.errorMessage = "Track not found";
            } else {
              console.error(e);
              this.errorMessage = e.message;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    clearState() {
      this.statuses = [];
      this.about = [];
      this.currentCity = null;
      this.routeStep = null;
      this.points = null;
      this.errorMessage = null;
    }
  }
}
</script>

<style scoped>
#track {
  padding-bottom: 128px;
}
</style>