<template>
  <layout-page 
    :anchors="[
      { path: 'why-us', label: $t('links.why_us') },
      { path: 'delivery', label: $t('links.delivery') },
      { path:'segments', label: $t('links.segments') },]" 
    :activeSection='activeSection'>
    <WhyUs />
    <DeliverySection />
    <Segments />
  </layout-page>
</template>

<script>
import LayoutPage from "@/components/LayoutPage.vue";
import WhyUs from "@/components/business/WhyUs.vue";
import DeliverySection from '@/components/home/DeliverySection'
import Segments from '@/components/business/Segments.vue'

export default {
  name: 'notFound',
  components: {
    LayoutPage,
    WhyUs,
    DeliverySection,
    Segments
  },
  data: () => ({
    activeSection: 'why-us',
  }),
  methods: {
    visibleSection() {
      const sections = document.querySelectorAll('section');
      for (const section of sections) {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight) {
          this.activeSection = section.id;
        }
      }
      return null;
    }
  },
  created () {
    window.addEventListener('scroll', this.visibleSection);
  },
  unmounted () {
    window.removeEventListener('scroll', this.visibleSection);
  },
}
</script>