<template>
  <section
    id="delivery"
    style="scroll-margin-top: 150px;"
  >
    <a-row class="section-row w100 delivery-row">
      <a-col
        :md="{ span: 15, order: 1 }"
        :sm="{ span: 24, order: 1 }"
        :xs="{ span: 24, order: 1 }"
        class="flex align-center justify-center pr-2 mt-sm-10 w100"
      >
        <img
          src="@/assets/images/Map.png"
          alt="map"
          class="bar-img map"
        >
      </a-col>
      <a-col
        :md="{ span: 9, order: 2 }"
        :sm="{ span: 24, oreder: 2 }"
        :xs="{ span: 24, oreder: 2 }"
        class="right-bar flex flex-column align-center justify-center mt-md-15 mt-sm-0 w100"
      >
        <div class="w100 ml-5">
          <h5 class="deliver-doc-title mb-6">
            {{ $t('deliverySection.title') }}
          </h5>
          <a-row class="w100">
            <a-col span="24">
              <a-flex
                align="center"
                justify="space-between"
                gap="20"
                class="flex-wrap"
              >
                <a-flex
                  v-for="(item, i) in countries"
                  :key="i"
                  align="center"
                  justify="start"
                  class="country-item"
                >
                  <img
                    :src="require(`@/assets/images/${item.image}`)"
                    alt="country"
                    class="icon-flag mr-2"
                  >
                  <span class="country-name">{{ $t(item.name) }}</span>
                </a-flex>
              </a-flex>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </section>
</template>

<script>
export default {
  name: 'DeliverySection',
  data() {
    return {
      countries: [
        {
          name: 'deliverySection.countries.russia',
          image: 'RU.png'
        },
        {
          name: 'deliverySection.countries.kazakhstan',
          image: 'KZ.png'
        },
        {
          name: 'deliverySection.countries.belarus',
          image: 'BY.png'
        },
        {
          name: 'deliverySection.countries.kyrgyzstan',
          image: 'KG.png'
        },
        {
          name: 'deliverySection.countries.mexico',
          image: 'flag-mexico.png'
        },
        {
          name: 'deliverySection.countries.uzbekistan',
          image: 'UZ.png'
        },
        {
          name: 'deliverySection.countries.tajikistan',
          image: 'TJ.png'
        },
        {
          name: 'deliverySection.countries.armenia',
          image: 'AM.png'
        },
        {
          name: 'deliverySection.countries.algeria',
          image: 'DZ.png'
        },
        {
          name: 'deliverySection.countries.bahrain',
          image: 'BH.png'
        },
        {
          name: 'deliverySection.countries.egypt',
          image: 'EG.png'
        },
        {
          name: 'deliverySection.countries.jordan',
          image: 'JO.png'
        },
        {
          name: 'deliverySection.countries.qatar',
          image: 'QA.png'
        },
        {
          name: 'deliverySection.countries.kuwait',
          image: 'KW.png'
        },
        {
          name: 'deliverySection.countries.malta',
          image: 'MT.png'
        },
        {
          name: 'deliverySection.countries.morocco',
          image: 'MA.png'
        },
        {
          name: 'deliverySection.countries.uae',
          image: 'AE.png'
        },
        {
          name: 'deliverySection.countries.oman',
          image: 'OM.png'
        },
        {
          name: 'deliverySection.countries.saudi_arabia',
          image: 'SA.png'
        },
        {
          name: 'deliverySection.countries.tunisia',
          image: 'TN.png'
        },
      ]
    }
  }
}
</script>

<style scoped>
.delivery-row{
  margin-top: 180px;
}

.deliver-doc-title{
  font-family: Raleway, sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  color: #000000;
}

.country-name{
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #07011F;
}

.country-item {
  display: flex;
  align-items: center;
  width: calc(50% - 10px);
}

@media screen and (max-width: 1050px) {
  .deliver-doc-title{
    font-size: 30px;
    line-height: 36px;
  }

  .country-name{
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1050px) {
  .deliver-doc-title{
    font-size: 30px;
    line-height: 36px;
  }

  .country-name{
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 850px) {
  .deliver-doc-title{
    font-size: 26px;
    line-height: 30px;
  }

  .country-name{
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 766px){
  .deliver-doc-title{
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .country-name{
    font-size: 20px;
    line-height: 27px;
  }

  .map{
    margin-top: 36px;
  }

  .delivery-row{
    margin-top: 100px;
  }
}
</style>