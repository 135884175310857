<template>
  <section
    id="why-us"
    style="scroll-margin-top: 200px !important; margin-bottom: 160px;"
  >
    <a-row class="section-row mt-15">
      <a-col
        :md="{ span: 14 }"
        :sm="{ span: 24 }"
        class="w100 flex align-center justify-center mt-lg-15 my-md-10 mt-sm-0 mb-sm-10"
      >
        <div class="left-bar-content mt-5 mt-sm-0">
          <h2 class="title" v-html="$t('whyUs.title')"></h2>
          <div class="info-component">
            <h4>{{ $t('whyUs.individualApproach') }}</h4>
            
            <a-button
              type="primary"
              class="btn-large deliver-doc-advantages-btn mt-13"
              @click="redirectTo('https://t.me/parcelsupport_bot')"
            >
              {{ $t('links.get_consultation') }}
            </a-button>
          </div>
        </div>
      </a-col>
      <a-col
        :md="{ span: 10 }"
        :sm="{ span: 24, order: 2 }"
        class="right-bar w100 flex align-center justify-end"
      >
        <img
          src="@/assets/images/Header business.avif"
          alt="layer"
          class="bar-img"
        >
      </a-col>
    </a-row>
    <a-row class="creepting-row">
      <div class="creepting-lines">
        <CreepingLine
          text="KAZAKHSTAN USA RUSSIA UZBEKISTAN"
          :rotation-deg="-4.68"
          background-color="#07011F"
          spin="left"
        />
        <CreepingLine
          text="PARCELPOINT"
          :rotation-deg="5.62"
          background-color="#FC0004"
          spin="right"
        />
      </div>
    </a-row>
    <a-row class="section-row busines-cis-row mb-11">
      <a-col
        :md="{ span: 14, order: 1 }"
        :sm="{ span: 24, order: 2 }"
        :xs="{ span: 24, order: 2 }"
        class="flex align-center justify-start pr-2 mt-sm-10"
      >
        <img
          src="@/assets/images/About busines.avif"
          alt="services"
          class="bar-img lg"
        >
        <img
          src="@/assets/images/Rectangle 5403.avif"
          alt="flash1"
          class="bar-img sm"
        >
      </a-col>
      <a-col
        :md="{ span: 10, order: 2 }"
        :sm="{ span: 24, oreder: 1 }"
        :xs="{ span: 24, oreder: 1 }"
        class="right-bar flex flex-column align-center justify-center mt-md-15 mt-sm-00"
      >
        <div class="position-bar flex flex-column align-center justify-center">
          <h5 class="deliver-doc-title" v-html="$t('whyUs.businessInCIS.title')"></h5>
          
          <a-row class="deliver-doc-advantages mt-lg-5 mt-md-0">
            <p>{{ $t('whyUs.businessInCIS.content1') }}</p>

            <p>{{ $t('whyUs.businessInCIS.content2') }}</p>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <a-row class="section-row expertise-row">
      <div class='expertise-background'></div>
      <a-col span='24'>
        <h4>{{ $t('whyUs.expertise.title') }}</h4>
        <a-flex class="expertise-area-row" align='center' justify='space-between'>
          <div class='expertise-area'>{{ $t('whyUs.expertise.b2c_segment') }}</div>
          <div class='expertise-area'>{{ $t('whyUs.expertise.e_commerce') }}</div>
          <div class='expertise-area'>{{ $t('whyUs.expertise.forwarding_services') }}</div>
        </a-flex>
        <a-flex class="expertise-area-row" align='center' justify='space-between'>
          <div class='expertise-area'>{{ $t('whyUs.expertise.sanctions_regulation') }}</div>
          <div class='expertise-area'>{{ $t('whyUs.expertise.development_of_it_solutions') }}</div>
        </a-flex>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import CreepingLine from '@/components/CreepingLine.vue'
export default {
  name: 'TrackingSection',
  components: {
    CreepingLine
  },
  methods: {
    redirectTo(link) {
      window.open(link, '_blank') ;
    }
  }
}
</script>

<style scoped>
.left-bar-content{
  max-width: 530px;
}

.title{
  font-family: Raleway;
  font-size: 48px;
  font-weight: 500;
  line-height: 56.35px;
  text-align: left;
}

.info-component h4{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #07011F;
}

.sm{
  display: none;
}

.creepting-row{
  height: 350px;
  overflow-x: hidden;
}

.creepting-lines{
  position: absolute;
  top: 633px;
  left: 0px;
  right: 0;
  width: 100%;
  z-index: 7;
  overflow: hidden;
  height: 450px;
}

.busines-cis-row{
  margin-top: 200px;
  margin-bottom: 200px;
}

.position-bar{
  position: absolute;
  bottom: -44px;
  right: 0;
  background-color: #fff;
  border-radius: 48px 0px 0px 0px;
  padding: 60px 0px 0px 60px;
  max-width: 644px;
  width: 150%;
}

.deliver-doc-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #000000;
}

.deliver-doc-advantages{
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color:#07011F;
}

.expertise-row{
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
}

.expertise-row h4{
  position: relative; 
  z-index: 2;
  font-family: Raleway;
  font-size: 64px;
  font-weight: 500;
  line-height: 75.14px;
  text-align: left;
  color: #07011F;
}

.expertise-background{
  background-color: #F4F4F4;
  position: absolute;
  z-index: 1;
  top: 0px;
  height: 100%; 
  left: 50%; 
  transform: translateX(-50%); 
  width: 100vw;
}

.expertise-area-row{
  position: relative; 
  z-index: 2; 
  max-width: 890px; 
  width: 100%;
  flex-wrap: wrap;
}

.expertise-area{
  font-family: Raleway;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #07011F;
  text-transform: uppercase;
  border: 1px solid #07011F;
  padding: 16px 32px 16px 32px;
  border-radius: 20px;
  margin-right: 10px;
  margin-top: 32px;
}

@media screen and (max-width: 1150px) {
  .title {
    font-size: 40px;
    line-height: 50px;
  }

  .creepting-lines{
    top: 500px;
    height: 250px;
  }

  .creepting-row{
    height: 250px;
  }

  .deliver-doc-title{
    font-size: 32px;
    line-height: 38px;
  }

}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 36px;
    line-height: 38px;
  }

  .creepting-lines{
    top: 450px
  }

  .creepting-row{
    height: 200px;
  }

  .deliver-doc-title{
    font-size: 28px;
    line-height: 32px;
  }

  .deliver-doc-btn{
    margin: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 960px) {
  .title {
    font-size: 30px;
    line-height: 36px;
  }

  .creeptng-lines{
    top: 671px;
  } 

  .deliver-doc-title{
    font-size: 22px;
    line-height: 27px;
  }

  .deliver-doc-advantages{
    font-size: 16px;
    line-height: 24px;
  }
  
}

@media screen and (max-width: 820px) {
  .creepting-lines{
    top: 450px;
  }

  .creepting-row{
    height: 200px;
  }

  .deliver-doc-title{
    font-size: 20px;
    line-height: 24px;
  }

  .deliver-doc-advantages{
    font-size: 14px;
    line-height: 21px;
  }
}

@media screen and (max-width: 766px) {
  .lg{
    display: none;
  }

  .sm{
    display: block;
  }

  .busines-cis-row{
    margin-top: 0px;
    margin-bottom: 100px;
  }

  .position-bar{
    position: static;
    background-color: #fff;
    border-radius:  0;
    padding: 0px;
    max-width: 100%;
    width: 100%;
  }

  .deliver-doc-title{
    font-size: 24px;
    line-height: 28px;
  }

  .deliver-doc-advantages{
    font-size: 20px;
    line-height: 30px;
  }

  .right-bar{
    justify-content: center;
  }

  .creepting-lines{
    top: 633px;
  }

  .creepting-row{
    height: 150px;
  }

  .deliver-doc-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 28.18px;
    text-align: left;
  }

  .deliver-doc-btn{
    margin: 0;
    margin-right: 15px;
  }

  .expertise-area-row{
    flex-direction: column;
    align-items: flex-start;
  }

  .expertise-area {
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px;
  }

  .expertise-row h4{
    font-size: 32px;
    line-height: 38px;
  }

  .expertise-row{
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
</style>