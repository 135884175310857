<template>
  <div class="side-wrapper">
    <div class="left-bar">
      <div style="position: relative; top: 0px; height: 100vh;">
        <ul class="side-menu">
        <li
          v-for="(anchor, index) in anchors"
          :key="anchor.path"
          :class="['side-menu-item', { 'active': activeSection === anchor.path}]"
        >
          <a v-if='index != 0' :href="'#' + anchor.path">{{ anchor.label }}</a>
          <a v-else href="#" @click='scrollToTop'>{{ anchor.label }}</a>
        </li>
      </ul>
      </div>
    </div>
    <div class="right-bar" />
  </div>
</template>

<script setup>
defineProps({
  anchors: {
    type: Array,
    default: () => []
  },
  activeSection: {
    type: String,
    default: null
  }
});
</script>

<script>
export default {
  name: "SideBar",
  methods: {
    scrollToTop() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }
  },
}
</script>


<style scoped>
.side-wrapper {
  padding-top: 80px;
}

.left-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  border-right: 1px solid #D1D1D1;
  z-index: 8;
  background-color: white;
}

.left-bar::after{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100px;
  height: 80px;
  border-bottom: 1px solid #D1D1D1;
  z-index: 8;
}

.right-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  height: 100vh;
  border-left: 1px solid #D1D1D1;
  z-index: 8;
  background-color: white;
}

.right-bar::after{
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 100px;
  height: 80px;
  border-bottom: 1px solid #D1D1D1;
  z-index: 8;
}

.side-menu {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 100px;
}

.side-menu-item {
  display: block;
  text-align: center;
  padding: 10px;
  transform: rotate(180deg);
  writing-mode: vertical-rl; 
}

.side-menu-item a::before {
  position: absolute;
  left: -53px;
  bottom: 50%;
  transform: rotate(-90deg);
  background-color: #787777;
  border-radius: 13px;
  content: '';
  width: 100%;
  height: 4px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 2;
}

.active a::before{
  opacity: 1;
}

.side-menu-item a{
  text-decoration: none;
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #000;
  position: relative;
  right: 25px;
  margin: 10px;
}

@media screen and (max-width: 764px) {
  .side-wrapper {
    display: none;
  }
}
</style>